const getOptionFields = fields => {
	return fields.split(',').filter(field => field.trim()).reduce((res, field) => {
		return `${res}&fields[]=${field.trim()}`;
	}, '');
};

const getOptionSort = sort => {
	const fields = sort.fields.split(',').reverse();
	const directions = sort.directions.split(',').reverse();
	return fields.filter(field => field).reduce((res, _, index) => {
		const field = `sort[${index}][field]=${fields[index]}`;
		const direction = `sort[${index}][direction]=${directions[index] || directions[directions.length - 1]}`;
		return `${res}&${field}&${direction}`;
	}, '');
}; // Function for getting request options


const getOption = (opt, key) => {
	let option;

	switch (key) {
		case 'fields':
			option = getOptionFields(opt);
			break;

		case 'sort':
			option = getOptionSort(opt);
			break;

		default:
			option = `&${key}=${opt}`;
	}

	return option;
};
/* Only for development */

/*
const getData = ({
  base,
  table,
  options,
  onRecords,
  onError,
}) => {
  if (!base) {
    onError(new Error('Base ID is required'));
    return;
  }

  const query = Object.keys(options).reduce((res, key) => {
    const option = getOption(options[key], key);
    return options[key] ? `${res}${option}` : res;
  }, '');
  
  const url = `https://api.airtable.com/v0/${base}/${table}?${query}`;
  const headers = {
    'Authorization': `Bearer patLHjFR4cGYynS2M.ddead0c6952f8abcf6bcb6881be8a2721159fa42420089415b8c9743369499cd`
  };

  fetch(encodeURI(url), { headers })
    .then(res => res.json())
    .then(({ records, error }) => {
      if (error) {
        onError(new Error('Error fetching data'));
        return;
      }
      onRecords(records);
      console.log("response data in ATD =" + JSON.stringify(records));
      console.log("Raw respons in ATD = " + records);
    }).catch(err => onError(err));
};

/* Development get data function ends here */

/* For live version */


const getData = async ({
	base,
	table,
	options,
	onRecords,
	onError
}) => {
	const query = Object.keys(options).reduce((res, key) => {
		const option = getOption(options[key], key);
		return options[key] ? `${res}${option}` : res;
	}, '');
	const url = `https://api.airtable.com/v0/${base}/${table}?${query}`;

	try {
		const response = await fetch('https://iqresearches.com/.netlify/functions/getAirtableData', {
			//const response = await fetch('/.netlify/functions/getAirtableData', {
			method: 'POST',
			body: JSON.stringify({
				url
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		});

		if (!response.ok) {
			throw new Error('Network response was not ok');
		}

		const data = await response.json();
		onRecords(data);
	} catch (error) {
		onError(new Error('Error fetching data:'));
		return; // or handle error as needed
	}
};
/* End of live version */


export default getData;