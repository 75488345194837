import React from 'react';
import atomize from '@quarkly/atomize';
import { Box, Text } from '@quarkly/widgets';
import { useOverrides } from '@quarkly/components';

const ProgressBar = ({
	current,
	total,
	...props
}) => {
	const {
		override,
		rest
	} = useOverrides(props, {}, {});
	const percentage = current / total * 100;
	return <Box {...rest}>
		        
		<Box
			position="relative"
			height="24px"
			background="#e0e0de"
			borderRadius="8px"
			overflow="hidden"
			{...override('Wrapper')}
		>
			            
			{current <= total ? <Text
				position="absolute"
				left="50%"
				top="-2px"
				z-index="1"
				transform="translate(-50%, -50%)"
				font="--base"
				{...override('PGLabel')}
			>
				                        
				{current}
				 of 
				{total}
				                
			</Text> : <Text
				position="absolute"
				left="50%"
				top="-2px"
				z-index="1"
				transform="translate(-50%, -50%)"
				font="--base"
				{...override('PGLabel')}
			>
				                        {"Completed"}                
			</Text>}
			                    
            
			<Box
				position="absolute"
				top="0"
				left="0"
				width={`${percentage}%`}
				height="100%"
				background="#4caf50"
				transition="width 0.2s ease-in-out"
			/>
			        
		</Box>
		        
	</Box>;
};

export default atomize(ProgressBar)({
	name: "ProgressBar",
	description: {
		en: "ProgressBar — visually represents the progress of a task"
	},
	propInfo: {
		current: {
			control: "input",
			category: 'Main',
			weight: 1
		},
		total: {
			control: "input",
			category: 'Main',
			weight: 1
		} // ... other props

	}
}, {
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true
});