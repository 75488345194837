// sendAnswers.js
const sendAnswersToServer = async enhancedSelectedAnswers => {
	//console.log("Enhanced selected answers: " + JSON.stringify(enhancedSelectedAnswers));
	try {
		const response = await fetch('https://iqresearches.com/.netlify/functions/saveSession', {
			//const response = await fetch('/.netlify/functions/saveSession', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(enhancedSelectedAnswers)
		});
		const data = await response.json(); //console.log('Success:', data);
		// Handle success response
	} catch (error) {
		console.error('Error:', error); // Handle error response
	}
};

export default sendAnswersToServer;