import React from 'react';
import atomize from "@quarkly/atomize";
import { Box, Input, Text, Icon, Link } from '@quarkly/widgets';
import Paypal from './Paypal';
import Stripe from './Stripe';
import ReactTooltip from "./ReactTooltip";
import { FaCcPaypal, FaExpeditedssl } from "react-icons/fa";

const PaymentForm = ({
	name,
	email,
	price,
	clientId,
	itemName,
	itemDescription,
	currencyCode,
	sessionId,
	onPaymentSuccess,
	isTrueSession,
	handleEmailInputChange,
	handleNameInputChange,
	override,
	...props
}) => {
	const enterNameText = <Box>
		      
		<Text font="--font-base">
			 No hidden fees, no subscriptions, no newsletters – just your results and certificate.
		</Text>
		      
		<Box
			display="grid"
			margin-top="45px"
			grid-template-columns="repeat(3, 1fr)"
			justify-items="center"
			align-items="center"
			{...override('PaymentFooterWrapper')}
		>
			        
			<ReactTooltip border="none" {...override('CustomTooltipIcon1')}>
				          
				<Icon
					category="fa"
					icon={FaCcPaypal}
					size="64px"
					color="#00457C"
					{...override('PaymentFooterIcon1')}
				/>
				        
			</ReactTooltip>
			        
			<ReactTooltip border="none" {...override('CustomTooltipIcon2')}>
				          
				<Icon
					category="fa"
					icon={FaExpeditedssl}
					size="64px"
					color="#00457C"
					{...override('PaymentFooterIcon2')}
				/>
				        
			</ReactTooltip>
			        
			<ReactTooltip border="none" {...override('CustomTooltipIcon3')}>
				          
				<Icon
					category="fa"
					icon={FaExpeditedssl}
					size="64px"
					color="#00457C"
					{...override('PaymentFooterIcon3')}
				/>
				        
			</ReactTooltip>
			 
      
		</Box>
		    
	</Box>;
	return <Box>
		    
		<Text font="--font-headline2" {...override('PayHeading')}>
			Pay here
		</Text>
		      
		{!isTrueSession && <Text font="--font-lead" color="--color-vividOrange">
			 NO SESSION FOUND!
		</Text>}
		      
		<Text {...override('Data Privacy')}>
			We don’t store payment details; transactions occur directly between your browser and the payment processor. See Stripe’s 
			<Link href="https://stripe.com/gb/privacy" {...override('PaypalLink')}>
				Privacy Statement
			</Link>
			 for more details.
		</Text>
		      
		<Stripe
			key={"REFRESH" + price}
			name={name}
			email={email}
			clientId={clientId}
			price={price}
			itemName={itemName}
			currencyCode={currencyCode}
			itemDescription={`${itemDescription} sid=${sessionId}`}
			onSuccess={(details, data) => onPaymentSuccess(details, data)}
			isTrueSession={isTrueSession}
			{...override('PayPal')}
		/>
		      
		<Text font="--lead" text-align="center">
			{enterNameText}
		</Text>
		    
	</Box>;
};

export default atomize(PaymentForm)({
	name: "PaymentForm",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "PaymentForm — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});