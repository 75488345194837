export const initialState = {
	components: [],
	records: [],
	error: '',
	isLoading: true
};
export function reducer(state, action) {
	switch (action.type) {
		case 'SET_COMPONENTS':
			return { ...state,
				components: action.components,
				error: null,
				isLoading: false
			};

		case 'SET_RECORDS':
			return { ...state,
				records: action.records,
				error: null,
				isLoading: false
			};

		case 'SET_ERROR':
			return { ...state,
				error: action.error,
				isLoading: false
			};

		default:
			return state;
	}
}
;
export default {
	initialState,
	reducer
};