import React, { useState, useEffect, useRef } from 'react';
import atomize from '@quarkly/atomize';
import { Box, Input, Text, Image, Icon, Strong, Section, Link } from '@quarkly/widgets';
import { useOverrides } from '@quarkly/components';
import Results from './Results';
import CertificateGenerator from './CertificateGenerator';
import { ScaleLoader } from 'react-spinners';
import getData from "./AirTableData";
import IncorrectQuestions from "./IncorrectQuestions";
import { scrollToId } from "./UxUtils";
import BellCurve from "./BellCurve";
import { sendEvent } from "./Footer";
import PricePanel from "./PricePanel";
import PaymentForm from "./PaymentForm";
import FeatureMenu from "./FeatureMenu";

const PaymentWrapper = ({
	clientId,
	itemName,
	currencyCode,
	itemDescription,
	...props
}) => {
	const [paymentCompleted, setPaymentCompleted] = useState(false);
	const [isTrueSession, setIsTrueSession] = useState(false);
	const [sessionId, setSessionId] = useState('');
	const [showResults, setShowResults] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [score, setScore] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const {
		override,
		children,
		rest
	} = useOverrides(props, {}, {});
	const isEmpty = !children || children.length === 0;
	const [price, setPrice] = useState(0); // Create refs for name and email

	const nameRef = useRef();
	const emailRef = useRef();
	const sessionIdRef = useRef();
	const [purchasedItems, setPurchasedItems] = useState({
		certificate: true,
		report: true,
		review: true
	});
	const purchaseRef = useRef();
	useEffect(() => {
		const _sessionId = getSessionId();

		sessionIdRef.current = _sessionId;
		setIsTrueSession(!!_sessionId);
	}, []);

	const updatePurchasedItems = items => {
		//console.log(items);
		setPurchasedItems(items);
		purchaseRef.current = items;
	};

	const getSessionId = () => {
		const sessionData = localStorage.getItem('sessionId'); // Check if sessionData is not null

		if (sessionData) {
			try {
				const {
					value,
					expires
				} = JSON.parse(sessionData); // Check if the session data includes the expires information

				if (expires && new Date().getTime() < expires) {
					setSessionId(value);
					return value;
				} else {
					// Clear the storage if the expiration is passed or no expiration data is found
					localStorage.removeItem('sessionId');
				}
			} catch (e) {
				// Handle cases where the session data is not in the expected JSON format
				console.log("Error parsing session data: ", e); // Optionally clear the invalid session data

				localStorage.removeItem('sessionId');
			}
		}

		return null;
	};

	const tagManagerArgs = {
		dataLayer: {
			event: 'purchase',
			//'purchase', 
			transactionId: sessionId,
			value: price,
			tax: 0.00,
			currency: currencyCode,
			email: email
		}
	}; // Update the refs whenever name or email changes

	useEffect(() => {
		nameRef.current = name;
		emailRef.current = email; //console.log("Purchaseref", purchaseRef);
	}, [name, email]);

	const fetchTotalScoreForSession = async sessionId => {
		return new Promise((resolve, reject) => {
			getData({
				base: "appzDuvSXNQvAOgrx",
				table: "SavedSessions",
				options: {
					view: "DefaultGrid",
					filterByFormula: `{SessionId} = "${sessionId}"`,
					fields: "TotalScore, Certificate, Report, Review"
				},
				onRecords: records => {
					if (records.length > 0) {
						const totalScore = records[0].fields.TotalScore;
						const certificate = records[0].fields.Certificate;
						const report = records[0].fields.Report;
						const review = records[0].fields.Review;
						setPurchasedItems({
							certificate: certificate ? true : false,
							report: report ? true : false,
							review: review ? true : false
						});
						resolve(totalScore);
					} else {
						reject(new Error("Session not found"));
					}
				},
				onError: reject
			});
		});
	};

	const onPaymentSuccess = async (details, data) => {
		// Accessing the name and email address
		const emailAddress = details.payer.email_address;
		const fullName = `${details.payer.name.given_name} ${details.payer.name.surname}`;
		setName(fullName);
		setEmail(emailAddress);
		scrollToId('topnav', 0, 0);
		updateSessionDetails(sessionIdRef.current, nameRef.current, emailRef.current, purchasedItems);
		setPaymentCompleted(true);
		localStorage.setItem('certificateDownloads', 0);
		sendEvent(tagManagerArgs); //console.log("Payment successful. Getting score...");

		try {
			const totalScore = await fetchTotalScoreForSession(sessionIdRef.current); //console.log("Total Score:", totalScore);

			setScore(totalScore);
			setShowResults(true);
		} catch (error) {
			console.error("Failed to get total score:", error);
		}
	};

	const updateSessionDetails = async (sessionId, name, email, purchasedItems) => {
		//console.log("Updating session with:", { sessionId, name, email, purchasedItems });
		try {
			const response = await fetch('https://iqresearches.com/.netlify/functions/updateSessionWithNameAndEmail', {
				//const response = await fetch('/.netlify/functions/updateSessionWithNameAndEmail', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					sessionId,
					name,
					email,
					purchasedItems
				})
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json(); //console.log('Success:', data);
			// Handle success response
		} catch (error) {
			console.error('Error:', error); // Handle error response
		}
	};

	const handleLoadingChange = loadingState => {
		setIsLoading(loadingState);
	};
	/* UX Control for results */

	/*useEffect(() => {
   const cleanupFunctions = [
     setupClickNScroll('price-box', 'payment-box'),
   ];
   // Cleanup on component unmount
   return () => {
     cleanupFunctions.forEach(cleanup => cleanup());
   };
 }, []);*/

	/* End of UX controls */


	const handleDataFromPricePanel = value => {
		setPrice(value);
	};

	return <Box {...rest} id="payment-wrapper">
		      
		{!paymentCompleted ? <Box {...override('Content')}>
			          
			<PricePanel
				{...override('PricePanel')}
				priceTotal={handleDataFromPricePanel}
				purchasedItems={updatePurchasedItems}
				priceBase={props.priceBase}
				priceMax={props.priceMax}
				priceCerti={props.priceCerti}
				priceReport={props.priceReport}
				priceReview={props.priceReview}
			/>
			          
			{isEmpty && <Text>
				No content provided.
			</Text>}
			          
			<Box id="payment-box" {...override('PaymentBox')}>
				            
				<PaymentForm
					name={name}
					email={email}
					price={price}
					clientId={clientId}
					itemName={itemName}
					itemDescription={itemDescription}
					currencyCode={currencyCode}
					sessionId={sessionId}
					onPaymentSuccess={onPaymentSuccess}
					isTrueSession={isTrueSession}
					override={override}
					{...override('PaymentFormnWrapper')}
				/>
				          
			</Box>
			          
			{<Text margin="0px 0px 0px 0px" font="--base">
				            By entering your name above, you ensure it's accurately displayed on your PDF certificate, a testament to your achievement. Additionally, providing an email address allows you to securely retrieve your results at any time via the 'Retrieve results' page, accessible from the menu.
          
			</Text>}
			          
			{<Text margin="0px 0px 0px 0px" font="--base">
				            We value your privacy; thus, you will only receive verification code to access your results. Rest assured, there are no hidden fees or subscriptions; this is a straightforward, one-time payment for your IQ test results and certificate.
          
			</Text>}
			          
			<Box {...override('GottaPay')} onClick={() => scrollToId('payment-box', 1000, 0)}>
				              
				<Box {...override('GPCWrapper')}>
					                
					<Text {...override('GottaPayLabel')} />
					                
					<Icon {...override('GPIcon')} />
					              
				</Box>
				            
			</Box>
			        
		</Box> : !showResults ? <Box {...override('Content')}>
			          
			{<Box display="grid" align-items="end" justify-items="center" {...override('loadingPG pay success wrapper')}>
				              
				<Image src="https://uploads.quarkly.io/65b3caaa009abe001f026b50/images/paid.png?v=2024-02-03T16:14:09.055Z" display="block" width="200px" />
				              
				<Text font="--lead">
					Successful payment. Thank you!
				</Text>
				            
			</Box>}
			          
			{<Box display="grid" align-items="end" justify-items="center" {...override('loader wrapper')}>
				              
				<Box transform="scale(3,2)">
					                
					<ScaleLoader color="#2A9D8F" />
					              
				</Box>
				              
				<Text font="--lead">
					Analysing test result...
				</Text>
				            
			</Box>}
			        
		</Box> : <>
			          
			<FeatureMenu purchasedItems={purchasedItems} {...override('FeatureMenu')} />
			          
			<Section {...override('ChartWrapper')}>
				            
				<BellCurve override={override} userScore={score} />
				          
			</Section>
			          
			{purchaseRef.current.report === false && <Section {...override('ScoreWrapper')}>
				              
				<Box>
					                
					<Text as="h2" {...override('OnlyScore')}>
						Your score is 
						{score}
					</Text>
					              
				</Box>
				            
			</Section>}
			          
			{purchaseRef.current.report && <Section {...override('Analytic Report')}>
				              
				{<Results score={score} onLoadingChange={handleLoadingChange} override={override} />}
				            
			</Section>}
			          
			{purchaseRef.current.certificate && <Section {...override('Certificate')}>
				              
				{<CertificateGenerator name={name} score={score.toString()} override={override} />}
				            
			</Section>}
			          
			{purchaseRef.current.review && <Section {...override('Failed Questions')}>
				              
				{<IncorrectQuestions id="questions" grid-column="1/ -1" sessionId={sessionId} override={override} />}
				            
			</Section>}
			        
		</>}
		    
	</Box>;
};

export default atomize(PaymentWrapper)({
	name: "PaymentWrapper",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "PaymentWrapper — my awesome component"
	},
	propInfo: {
		/*price: {
    title: "Price",
    control: "input",
    category: "Item"
  },*/
		itemName: {
			title: "Name",
			control: "input",
			category: "Item"
		},
		itemDescription: {
			title: "Description",
			control: "text-area",
			category: "Item"
		},
		currencyCode: {
			title: "Currency code",
			control: "input",
			category: "Main"
		},
		clientId: {
			title: "Client ID",
			control: "input",
			category: "Main"
		},
		priceBase: {
			title: "Min price",
			control: "input",
			type: "number",
			category: "Pricing",
			weight: 0.5
		},
		priceMax: {
			title: "Max price cap",
			control: "input",
			type: "number",
			category: "Pricing",
			weight: 0.5
		},
		priceCerti: {
			title: "Certificate's price",
			control: "input",
			type: "number",
			category: "Pricing",
			weight: 1
		},
		priceReport: {
			title: "Report's price",
			control: "input",
			type: "number",
			category: "Pricing",
			weight: 1
		},
		priceReview: {
			title: "Question review's price",
			control: "input",
			type: "number",
			category: "Pricing",
			weight: 1
		}
	}
}, {
	price: "1",
	currencyCode: "USD",
	clientId: "sb",
	priceBase: 0,
	priceMax: 100,
	priceCerti: 0,
	priceReport: 0,
	priceReview: 0
});