import React, { useState, useEffect } from 'react';
import atomize from "@quarkly/atomize";
import getData from "./AirTableData"; // Ensure this function is correctly implemented to use Airtable API

import { Section, Box, Input, Text, Image, Button } from '@quarkly/widgets';
import { RingLoader } from 'react-spinners';

function formatDate(dateString) {
	const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	const date = new Date(dateString);
	const day = date.getDate();
	const monthIndex = date.getMonth();
	const year = date.getFullYear(); // Function to add ordinal suffix to day

	function getOrdinalSuffix(day) {
		if (day > 3 && day < 21) return 'th'; // for days like 4th, 5th to 20th

		switch (day % 10) {
			case 1:
				return "st";

			case 2:
				return "nd";

			case 3:
				return "rd";

			default:
				return "th";
		}
	}

	return `${months[monthIndex]} ${day}${getOrdinalSuffix(day)} ${year}`;
}

const RetrieveResults = ({
	sessionId,
	override,
	...rest
}) => {
	//const [email, setEmail] = useState('');
	const [sessions, setSessions] = useState([]);
	const [carouselIndex, setCarouselIndex] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {//console.log(carouselIndex);
	}, [carouselIndex]);

	const updateSessionIndexes = sessionsData => {
		const initialIndexes = {};
		sessionsData.forEach(session => {
			initialIndexes[session.id] = 0; // Initialize each session's carousel index to 0
		});
		setCarouselIndex(initialIndexes);
	};

	useEffect(() => {
		getIncorrectAnswers();
	}, []); // Function to fetch all questions for wrong answers of a session

	const fetchQuestionsForSession = async wrongAnswers => {
		const questionIds = wrongAnswers.map(answerId => answerId.toString().substring(0, 5));
		const uniqueQuestionIds = [...new Set(questionIds)]; // Adjusted to manage carousel index for each session

		const questions = await Promise.all(uniqueQuestionIds.map(questionId => new Promise((resolve, reject) => {
			getData({
				base: "appzDuvSXNQvAOgrx",
				table: "TestQA",
				options: {
					view: "DefaultGrid",
					filterByFormula: `{QuestionId} = "${questionId}"`,
					fields: ""
				},
				onRecords: resolve,
				onError: reject
			});
		})));
		return questions.flat(); // Assuming getData resolves with the record data directly
	};

	const getIncorrectAnswers = async () => {
		setIsLoading(true); // Start loading

		try {
			const sessionsData = await new Promise((resolve, reject) => {
				getData({
					base: "appzDuvSXNQvAOgrx",
					table: "SavedSessions",
					options: {
						view: "DefaultGrid",
						filterByFormula: `{SessionId} = "${sessionId}"`,
						fields: ""
					},
					onRecords: resolve,
					onError: reject
				});
			}); //console.log(JSON.stringify(sessionsData));
			// Fetch questions for each session's wrong answers

			for (const session of sessionsData) {
				const wrongAnswers = JSON.parse(session.fields.WrongAnswers || '[]');
				session.questions = await fetchQuestionsForSession(wrongAnswers);
			}

			setSessions(sessionsData);
			updateSessionIndexes(sessionsData);
			setIsLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setIsLoading(false); // Ensure loading ends even if there's an error
		}
	}; // Function to handle next image in carousel for a specific session
	// Function to handle next image in carousel for a specific session


	const nextQuestion = sessionId => {
		setCarouselIndex(prevIndex => {
			const sessionQuestionsLength = sessions.find(session => session.id === sessionId).questions.length;
			const nextIndex = (prevIndex[sessionId] + 1) % sessionQuestionsLength;
			return { ...prevIndex,
				[sessionId]: nextIndex
			};
		});
	}; // Function to handle previous image in carousel for a specific session


	const prevQuestion = sessionId => {
		setCarouselIndex(prevIndex => {
			const sessionQuestionsLength = sessions.find(session => session.id === sessionId).questions.length;
			const prevIndexValue = prevIndex[sessionId];
			const newIndex = prevIndexValue - 1 < 0 ? sessionQuestionsLength - 1 : prevIndexValue - 1;
			return { ...prevIndex,
				[sessionId]: newIndex
			};
		});
	};

	return <Box {...rest}>
		      
		{
			/* Render Loading message when isLoading is true */
		}
		      
		{isLoading && <Box margin-top="30px" display="grid" align-items="end" justify-items="center">
			          
			<Box>
				            
				<RingLoader color="#2A9D8F" />
				          
			</Box>
			          
			<Text margin="30px" font="--lead">
				Loading...
			</Text>
			        
		</Box>}
		      
		{sessions.length < 1 && !isLoading && <Box margin-top="30px" display="grid" align-items="end" justify-items="center">
			          
			<Text margin="30px" font="--lead">
				No results found!
			</Text>
			        
		</Box>}
		      
		{sessions.length > 0 && sessions.map((session, index) => <>
			          
			<Box>
				            
				<Text as="h2" {...override('Title')}>
					 Incorrectly Answered Questions 
				</Text>
				          
			</Box>
			          
			<Box
				key={session.id}
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				background="--color-light"
				sm-grid-template-columns="repeat(1, 1fr)"
				{...override('Failed Questions Wrapper')}
			>
				            
				{
					/* Session details */
				}
				            
				<Box
					padding="0 0px 0px 25px"
					display="grid"
					align-content="start"
					grid-gap="16px"
					{...override('First Col')}
				>
					              
					<Box>
						                
						<Text {...override('Date label')} font="--lead" color="--primary">
							Test taken on: 
							{formatDate(session.fields.Created)}
						</Text>
						              
					</Box>
					              
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						justify-content="flex-start"
						align-items="center"
						sm-justify-content="center"
						{...override('Buttons Wrapper')}
					>
						                
						<Button
							border-radius="15px 0 0 15px"
							min-width="120px"
							onClick={() => prevQuestion(session.id)}
							disabled={session.questions.length <= 1}
							{...override('Prev Btn')}
						>
							                  Previous
                
						</Button>
						                
						<Button
							border-radius="0 15px 15px 0"
							min-width="120px"
							onClick={() => nextQuestion(session.id)}
							disabled={session.questions.length <= 1}
							{...override('Next Btn')}
						>
							                  Next
                
						</Button>
						              
					</Box>
					              
					<Text
						font="--base"
						color="--primary"
						max-width="90%"
						sm-max-width="100%"
						{...override('Description')}
					>
						Review the questions you answered incorrectly now or anytime later at the Retrieve Results page. Questions that were skipped are not included in this assessment.
					</Text>
					            
				</Box>
				            
				{session.questions && session.questions.length > 0 ? <Box background="--color-lightD1" padding="25px 25px 25px 25px" {...override('Second Col')}>
					                
					<Text font="--headline3" color="--primary" {...override('SubTitle')}>
						Failed Questions: 
						{session.questions.length}
					</Text>
					                
					<Box min-width="100px" min-height="100px">
						                  
						<Text font="--lead" color="--primary" {...override('Counter label')}>
							Question 
							{carouselIndex[session.id] + 1}
							 of 
							{session.questions.length}
						</Text>
						                
					</Box>
					                
					<Box min-width="100px" min-height="100px">
						                  
						<Image src={session.questions[carouselIndex[session.id] || 0].fields.QuestionImage[0].url} alt={`Question ${carouselIndex[session.id] || 0 + 1}`} style={{
							maxWidth: '100%',
							display: 'block'
						}} />
						                
					</Box>
					              
				</Box> : <Text margin="25px" font="--base" color="--primary" {...override('Empty info')}>
					No incorrectly answered questions were detected. Questions that were skipped are not included in this assessment.
				</Text>}
				          
			</Box>
			        
		</>)}
		    
	</Box>;
};

export default atomize(RetrieveResults)({
	name: "RetrieveResults",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "RetrieveResults — a component to fetch and display results based on user's email, including incorrectly answered questions."
	},
	propInfo: {}
});