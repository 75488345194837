import getData from './AirTableData';
const scoreRequirements = {
	"4": 10,
	"5": 10,
	"6": 10
}; // Set requirements { "[Point]":[Amount of questions] }

function transformQuestions(inputQuestions) {
	return {
		appExamId: 6666,
		// Assuming this is static or comes from elsewhere
		examId: "IQZ",
		// Assuming this is static or comes from elsewhere
		examQuestions: inputQuestions.map(question => {
			const {
				QuestionId,
				Instruction,
				QuestionImage,
				AnswerImg
			} = question.fields;
			const questionImg = QuestionImage[0].url; // Assuming there's always at least one image

			const answerImg = AnswerImg[0].url; // Assuming there's always at least one image
			// Generate examAnswers

			const examAnswers = [];

			for (let i = 1; i <= 6; i++) {
				examAnswers.push({
					examAnswerId: parseInt(`${QuestionId}0${i}`, 10),
					score: question.fields[i.toString().padStart(2, '0')] || 0
				});
			}

			return {
				examQuestionId: QuestionId,
				instruction: Instruction,
				questionImg: questionImg,
				answerImg: answerImg,
				examAnswers: examAnswers
			};
		})
	};
}

function filterAndSelectQuestions(inputQuestions, scoreRequirements) {
	// Helper function to shuffle array
	function shuffleArray(array) {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}

		return array;
	} // Filter questions by score and select randomly


	const filteredQuestions = [];

	for (const [score, count] of Object.entries(scoreRequirements)) {
		const questionsWithScore = inputQuestions.filter(question => Object.values(question.fields).includes(parseInt(score)));
		shuffleArray(questionsWithScore); // Shuffle to randomize selection

		filteredQuestions.push(...questionsWithScore.slice(0, count));
	}

	return filteredQuestions;
}

function transformAndSelectQuestions(inputQuestions) {
	const selectedQuestions = filterAndSelectQuestions(inputQuestions, scoreRequirements);
	return transformQuestions(selectedQuestions);
}

const getQuestions = async () => {
	return new Promise((resolve, reject) => {
		getData({
			base: "appzDuvSXNQvAOgrx",
			table: "TestQA",
			options: {
				view: "DefaultGrid",
				fields: "",
				filterByFormula: "",
				sort: {
					fields: "",
					directions: ""
				}
			},
			onRecords: records => {
				// Place your transformation logic here
				const transformedRecords = transformAndSelectQuestions(records);
				resolve(transformedRecords); // Resolve the promise with the transformed data
			},
			onError: error => {
				reject(error); // Reject the promise if there's an error
			}
		});
	});
};

export default getQuestions;