import React from 'react';
import { Box } from '@quarkly/widgets'; // A component with error messages displayed

const ComponentNotice = ({
	message,
	...props
}) => <Box
	padding="16px"
	width="100%"
	font="--font-base"
	font-style="italic"
	color="--color-grey"
	background-color="--color-light"
	border="1px dashed --color-lightD2"
	box-sizing="border-box"
	{...props}
>
	    
	{message || 'Some Text'}
	  
</Box>;

Object.assign(ComponentNotice, {
	propInfo: {
		message: {
			title: 'Message',
			control: 'input',
			type: 'text',
			weight: 1
		}
	}
});
export default ComponentNotice;