import React, { useState, useEffect, useRef } from 'react';
import atomize from '@quarkly/atomize';
import { Box, Text, Button, Image, Link, Strong } from '@quarkly/widgets';
import { scrollToId } from './UxUtils';
import ProgressBar from './ProgressBar';

const shuffle = array => {
	let currentIndex = array.length,
	    randomIndex; // While there remain elements to shuffle in the main array (questions)...

	while (currentIndex !== 0) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--; // And swap it with the current element. 

		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	} // Now shuffle answers within each question


	array.forEach(question => {
		let currentAnswerIndex = question.examAnswers.length; // While there remain elements to shuffle in the answers array...

		while (currentAnswerIndex !== 0) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentAnswerIndex);
			currentAnswerIndex--; // And swap it with the current element.

			[question.examAnswers[currentAnswerIndex], question.examAnswers[randomIndex]] = [question.examAnswers[randomIndex], question.examAnswers[currentAnswerIndex]];
		}
	});
	return array;
};

const IqTest = ({
	totalQuestions,
	questionsData,
	currentQuestionIndex,
	//setTotalScore,
	goToNextQuestion,
	goToPreviousQuestion,
	onGetResults,
	onComplete,
	selectedAnswers,
	setSelectedAnswers,
	override,
	...rest
}) => {
	//const { override, children, rest } = useOverrides(props, {}, {});
	const endTime = new Date().getTime() + 20 * 60 * 1000.5; // 20 minutes limit

	const [timeLeft, setTimeLeft] = useState(endTime - new Date().getTime());
	const [timeout, setTimeout] = useState(false);
	useEffect(() => {
		const timerId = setInterval(() => {
			const now = new Date().getTime();
			const distance = endTime - now;

			if (distance < 0) {
				clearInterval(timerId);
				setTimeout(true);
			} else {
				setTimeLeft(distance);
			}
		}, 1000);
		scrollToId('progress-bar', 3000, 3000);
		return () => clearInterval(timerId);
	}, []);

	const checkUnansweredQuestions = () => {
		const questions = Object.keys(shuffledQuestions).length - Object.keys(selectedAnswers).length; //console.log('Unanswered = ' + questions);

		return questions;
	};

	const formatTime = time => {
		const minutes = Math.floor(time % (1000 * 60 * 60) / (1000 * 60));
		const seconds = Math.floor(time % (1000 * 60) / 1000);
		return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	}; //const [selectedAnswers, setSelectedAnswers] = useState({});


	const [shuffledQuestions, setShuffledQuestions] = useState(questionsData.examQuestions);
	useEffect(() => {
		setShuffledQuestions(shuffle([...questionsData.examQuestions]));
	}, []); // Empty dependency array means this effect runs once on mount

	const questions = shuffledQuestions;
	/*useEffect(() => {
   console.log("selected answers: " + JSON.stringify(selectedAnswers));
 }, [selectedAnswers]);*/

	const handleOptionSelect = selectedAnswerId => {
		const question = questions[currentQuestionIndex]; //const selectedAnswer = question.examAnswers.find(answer => answer.examAnswerId === selectedAnswerId);
		// Get the previously selected answer ID for the current question
		//const prevSelectedAnswerId = selectedAnswers[question.examQuestionId];
		// Find the score of the previously selected answer (if it exists)
		//const prevAnswerScore = prevSelectedAnswerId ? question.examAnswers.find(answer => answer.examAnswerId === prevSelectedAnswerId).score : 0;
		// Update the selected answers state

		const newSelectedAnswers = { ...selectedAnswers,
			[question.examQuestionId]: selectedAnswerId
		};
		setSelectedAnswers(newSelectedAnswers); // Update total score: Subtract previous answer score (if any) and add current answer score
		//setTotalScore((prevTotalScore) => prevTotalScore - prevAnswerScore + selectedAnswer.score);

		goToNextQuestion();
	};

	const containerRef = useRef(null); // Ref for the container

	const [backgroundPositions, setBackgroundPositions] = useState(['-2% -0.8%', // Top Left
	'101.8% -0.8%', // Top Right
	'-2% 50%', // Middle Left
	'101.8% 50%', // Middle Right
	'-2% 100.8%', // Bottom Left
	'101.8% 100.8%' // Bottom Right
	]); // Preload imgs

	useEffect(() => {
		// Function to create and append preload link
		const createPreloadLink = url => {
			const preloadLink = document.createElement("link");
			preloadLink.href = url;
			preloadLink.rel = "preload";
			preloadLink.as = "image";
			document.head.appendChild(preloadLink);
			return preloadLink;
		};

		const preloadLinks = [questions[currentQuestionIndex + 1]?.questionImg, shuffledQuestions[currentQuestionIndex + 1]?.answerImg].filter(Boolean).map(createPreloadLink); // Cleanup    

		return () => preloadLinks.forEach(link => document.head.removeChild(link));
	}, [currentQuestionIndex, shuffledQuestions]);
	return <Box {...rest} min-width="100px" min-height="100px" id="test">
		      
		{timeout || currentQuestionIndex > questionsData.examQuestions.length - 1 ? <Box flex-direction="column" display="flex" align-items="center">
			          
			<Text margin="25px auto" font="--lead" color="--color-primary">
				            
				{timeout ? "No remaining time left." : "Congrats! You've completed the test."}
				          
			</Text>
			          
			{checkUnansweredQuestions() > 0 && !timeout && <Text
				font="--base"
				background="--color-sandyBrown"
				padding="15px"
				border-radius="15px"
				color="--color-light"
			>
				              
				<Strong>
					                
					{checkUnansweredQuestions()}
					 questions remain unanswered. There's still time to complete them!!
              
				</Strong>
				            
			</Text>}
			          
			<Link
				margin="25px"
				width="200px"
				border-radius="8px"
				font="--lead"
				text-decoration="none"
				hover-color="--darkL1"
				hover-background="rgba(63, 36, 216, 0)"
				transition="background-color 0.2s ease 0s"
				background="--color-orange"
				color="--light"
				href="/payment"
				target="_self"
				hover-border-color="--color-purple"
				hover-border-width="2px"
				hover-border-style="solid"
				border-width="2px"
				border-style="solid"
				border-color="--color-orange"
				onClick={onGetResults}
				padding="12px 28px 12px 28px"
				text-align="center"
			>
				            
				<Strong>
					              Get Results

            
				</Strong>
				          
			</Link>
			        
		</Box> : <>
			          
			<Box>
				            
				<Text font="--base" {...override('CountDown')}>
					Time left: 
					{formatTime(timeLeft)}
				</Text>
				          
			</Box>
			          
			<Box id="instruction">
				            
				<Text font="--base" {...override('Introduction')}>
					{questions[currentQuestionIndex].instruction}
				</Text>
				          
			</Box>
			          
			<ProgressBar id='progress-bar' {...override('ProgressBar')} current={currentQuestionIndex + 1} total={totalQuestions} />
			          
			<Box
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="repeat(1, 1fr)"
				grid-gap="100px"
				sm-grid-gap="25px"
			>
				            
				<Box min-width="100px" min-height="100px" id="question" {...override('Question')}>
					              
					<Image src={questions[currentQuestionIndex].questionImg} display="block" width="100%" />
					            
				</Box>
				            
				<Box
					min-width="100px"
					min-height="100px" //max-width="600px"

					margin="0px auto 0px auto"
					display="grid"
					grid-gap="25px"
					sm-grid-gap="5px"
					grid-template-columns="repeat(3, 1fr)"
					align-content="center"
					id="page"
					{...override('OptionsWrapper')}
				>
					              
					{questions[currentQuestionIndex].examAnswers.map((answer, index) => {
						// Mapping index to letter (0 -> 'A', 1 -> 'B', ...)
						const letter = String.fromCharCode('A'.charCodeAt(0) + index);
						const isSelected = selectedAnswers[questions[currentQuestionIndex].examQuestionId] === answer.examAnswerId; // Style or class to indicate selection

						const answerStyle = isSelected ? {
							backgroundColor: "rgba(244, 162, 97, 0.2)"
						} : {};
						const answerImgUrl = shuffledQuestions[currentQuestionIndex].answerImg;
						return <Box {...rest}>
							                    
							<Box
								{...override('OptionWrapper')}
								min-width="100px"
								min-height="100px"
								key={answer.examAnswerId}
								style={answerStyle}
								class="answer"
							>
								                      
								<Button
									ref={containerRef}
									background-origin="padding-box"
									background-size="225%"
									{...override('Option')}
									background={`url(${answerImgUrl}) ${backgroundPositions[answer.examAnswerId - shuffledQuestions[currentQuestionIndex].examQuestionId * 100 - 1]} no-repeat`}
									onClick={() => handleOptionSelect(answer.examAnswerId)}
								/>
								                      
								<Text margin="0" text-align="center" font="--base" {...override('Label')}>
									                        
									{letter}
									                      
								</Text>
								                    
							</Box>
							                  
						</Box>;
					})}
					            
				</Box>
				          
			</Box>
			        
		</>}
		      
		{
			/* Navigation buttons */
		}
		      
		<Box
			min-width="100px"
			min-height="100px"
			min-width="120px"
			sm-margin="30px"
			margin="80px auto"
			text-align="center"
		>
			        
			<Button border-radius="15px 0 0 15px" onClick={goToPreviousQuestion} disabled={currentQuestionIndex === 0}>
				Previous
			</Button>
			        
			<Button border-radius="0 15px 15px 0" min-width="120px" onClick={goToNextQuestion} disabled={currentQuestionIndex === questions.length}>
				Next
			</Button>
			      
		</Box>
		    
	</Box>;
};

export default atomize(IqTest)({
	name: "IQTest",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "TestPage — component to display and navigate the IQ test questions"
	},
	propInfo: {
		// Define prop types, default values, and descriptions as needed
		// For example:
		currentQuestionIndex: {
			title: "Current Question Index",
			control: "input",
			category: "Main",
			description: {
				en: "Index of the current question being displayed"
			}
		} // ... other props

	}
});