import React, { useState, useEffect } from 'react';
import { Box, Text, Strong } from '@quarkly/widgets';
import atomize from "@quarkly/atomize";
import AirTable from "./AirTable";
import { scrollToId } from "./UxUtils";

const iqCategoryDescription = score => {
	let description;

	switch (true) {
		case score >= 145:
			//description = "Your score places you in the 'Exceptionally Gifted' category, indicating profound intellectual capabilities and exceptional analytical skills.";
			description = "145-150";
			break;

		case score >= 130 && score <= 144:
			//description = "As a 'Moderately Gifted' individual, your score suggests advanced reasoning abilities and a strong aptitude for complex problem-solving.";
			description = "130-144";
			break;

		case score >= 115 && score <= 129:
			//description = "Your score in the 'Above Average or Bright' range reflects higher-than-average intellectual skills, with well-developed reasoning and analytical abilities.";
			description = "115-129";
			break;

		case score >= 85 && score <= 114:
			//description = "Your score falls within the 'Average Intelligence' range, typical for most people, indicating a balanced ability in handling intellectual tasks.";
			description = "85-114";
			break;

		case score >= 70 && score <= 84:
			//description = "In the 'Borderline Mental Disability' range, your score may suggest challenges in certain cognitive areas. Remember, IQ is just one aspect of intelligence.";
			description = "70-84";
			break;

		case score < 70:
			//description = "This score range indicates varying degrees of mental disability, which might present challenges in cognitive tasks. IQ scores do not fully define your abilities or potential.";
			description = "0-70";
			break;

		default:
			description = "Invalid score.";
	}

	return description;
};

const Results = ({
	score,
	onLoadingChange,
	override,
	...rest
}) => {
	return <Box {...rest}>
		      
		<Box {...override('Title Wrapper')}>
			        
			<Text as="h2" {...override('AR Title')}>
				Analytical report
			</Text>
			        
			<Text font="--lead" {...override('Score')}>
				Your score is 
				{score}
			</Text>
			      
		</Box>
		      
		<AirTable
			base="appzDuvSXNQvAOgrx"
			table="ResultDescriptions"
			view="DefaultGrid"
			testShowLoading={false}
			fields="Title, Description"
			filterByFormula={`{Name} = '${iqCategoryDescription(score)}'`}
			onLoadingChange={onLoadingChange}
			{...override('AirTable Wrapper')}
		/>
		      
		{
			/* Additional results display */
		}
		    
	</Box>;
};

export default atomize(Results)({
	name: "Results",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "Results — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});