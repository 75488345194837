export const setupClickNScroll = (linkBoxId, targetId, scrollWhenTargetFullyVisible = false) => {
	/* Example usage - insert the following */

	/*
 useEffect(() => {
 const cleanupFunctions = [
 setupClickNScroll('get-certificate-btn','certificate', true ),
 setupClickNScroll('price-box','payment-box'),
 ];
 // Cleanup on component unmount
 return () => {
 cleanupFunctions.forEach(cleanup => cleanup());
 };
 }, []);
 */
	// Select the element with the ID 'price-box'
	const linkBox = document.getElementById(linkBoxId); // Ensure the element exists before adding the listener

	if (!linkBox) return;

	function handleClick(event) {
		// Prevent default action if necessary
		event.preventDefault();
		const duration = 2000; // Example duration in milliseconds

		const delay = 0; // Example delay in milliseconds
		// Select the target element to check its visibility

		const targetElement = document.getElementById(targetId);

		if (targetElement) {
			const bounding = targetElement.getBoundingClientRect();
			const isFullyVisible = bounding.top >= 0 && bounding.left >= 0 && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight); // Only call ScrollToId if the target element is not fully visible

			if (scrollWhenTargetFullyVisible || !isFullyVisible) {
				scrollToId(targetId, duration, delay);
			}
		}
	} // Add click event listener to the 'price-box'


	linkBox.addEventListener('click', handleClick); // Cleanup function to remove the event listener

	return () => {
		if (linkBox) {
			linkBox.removeEventListener('click', handleClick);
		}
	};
}; // Function to scroll smoothly to the element with the ID 'question'

export const scrollToId = (elementId, duration = 2000, delay = 2000) => {
	setTimeout(() => {
		const targetElement = document.getElementById(elementId);

		if (!targetElement) {
			return;
		}

		const targetPosition = targetElement.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
		const startPosition = window.pageYOffset;
		const distance = targetPosition - startPosition;
		let startTime = null;

		function animation(currentTime) {
			if (startTime === null) startTime = currentTime;
			const timeElapsed = currentTime - startTime;
			const run = easeInOutCubic(timeElapsed, startPosition, distance, duration);
			window.scrollTo(0, run);
			if (timeElapsed < duration) requestAnimationFrame(animation);
		}

		function easeInOutCubic(t, b, c, d) {
			t /= d / 2;
			if (t < 1) return c / 2 * t * t * t + b;
			t -= 2;
			return c / 2 * (t * t * t + 2) + b;
		}

		requestAnimationFrame(animation);
	}, delay);
};
export const showItem = elementId => {
	if (typeof document !== "undefined") {
		const element = document.getElementById(elementId);

		if (element) {
			//console.log('show item');
			element.style.position = 'static';
			element.style.opacity = '1';
		} else {
			console.log('Element with ID ' + elementId + ' not found.');
		}
	} else {
		console.log('document is not available');
	}
};
export const hideItem = elementId => {
	if (typeof document !== "undefined") {
		const element = document.getElementById(elementId);

		if (element) {
			//console.log('hide item');
			element.style.position = 'absolute';
			element.style.opacity = '0';
			element.style.zIndex = '-10';
		} else {
			console.log('Element with ID ' + elementId + ' not found.');
		}
	} else {
		console.log('document is not available');
	}
};
export default {
	scrollToId,
	setupClickNScroll,
	showItem,
	hideItem
};