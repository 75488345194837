import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Icon, Box, Section } from "@quarkly/widgets";
import { scrollToId } from "./UxUtils";
import { BsFillPieChartFill, BsXCircle } from "react-icons/bs";
import { AiOutlineFilePdf } from "react-icons/ai";
const defaultProps = {
	"background": "linear-gradient(0,rgba(42, 157, 143, 0.15) 0%,#ffffff 100%)",
	"padding": "24px 0 100px 0",
	"id": "top-shortcuts",
	"position": "absolute",
	"z-index": "-10",
	"opacity": "0",
	"bottom": "996.438px",
	"top": "132px"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-gap": "60px",
			"sm-grid-gap": "30px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline1",
			"color": "--primary",
			"text-align": "center",
			"as": "h1",
			"sm-font": "normal 600 38px/1.2 \"Poppins\", sans-serif",
			"sm-text-align": "left",
			"opacity": "0.8",
			"children": "Your results"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"md-grid-template-columns": "1fr",
			"place-items": "center",
			"align-items": "stretch",
			"justify-items": "stretch"
		}
	},
	"AnalyticalReport": {
		"kind": "Box",
		"props": {
			"background": "linear-gradient(0deg,rgba(38, 70, 83, 0.5) 0%,rgba(0,0,0,0) 35.7%),--color-secondary",
			"display": "grid",
			"border-radius": "3px 30px 3px 30px",
			"place-self": "center",
			"hover-box-shadow": "none",
			"box-shadow": "0 20px 15px -6px --color-primary",
			"hover-transition": "all 1s ease 0s",
			"id": "report-btn",
			"cursor": "pointer"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "25px 10px 0 25px",
			"display": "grid",
			"justify-items": "end",
			"width": "100%",
			"hover-color": "--lightD2",
			"hover-transition": "all 1s ease 0s",
			"color": "--light"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 700 32px/1.2 --fontFamily-googlePoppins",
			"color": "--secondary",
			"mix-blend-mode": "difference",
			"sm-font": "normal 600 32px/1.2 \"Poppins\", sans-serif",
			"as": "h2",
			"width": "100%",
			"min-height": "86.375px",
			"display": "flex",
			"align-items": "center",
			"children": "Analytical Report"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsFillPieChartFill,
			"size": "64px",
			"hover-border-color": "--color-primary",
			"width": "50px",
			"opacity": "0.8"
		}
	},
	"GetCertificate": {
		"kind": "Box",
		"props": {
			"background": "linear-gradient(0deg,rgba(38, 70, 83, 0.5) 0%,rgba(0,0,0,0) 35.7%),--color-lightBlue",
			"display": "grid",
			"border-radius": "3px 30px 3px 30px",
			"place-self": "center",
			"hover-box-shadow": "none",
			"box-shadow": "0 20px 15px -6px --color-primary",
			"hover-transition": "all 1s ease 0s",
			"id": "certificate-btn",
			"cursor": "pointer"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "25px 10px 0 25px",
			"display": "grid",
			"justify-items": "end",
			"width": "100%",
			"hover-color": "--lightD2",
			"hover-transition": "all 1s ease 0s",
			"color": "--light"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 700 32px/1.2 --fontFamily-googlePoppins",
			"color": "--vividOrange",
			"mix-blend-mode": "multiply",
			"border-color": "--color-lightBlue",
			"sm-font": "normal 600 32px/1.2 \"Poppins\", sans-serif",
			"as": "h2",
			"width": "100%",
			"min-height": "86.375px",
			"display": "flex",
			"align-items": "center",
			"children": "Get Certificate"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "ai",
			"icon": AiOutlineFilePdf,
			"size": "64px",
			"hover-border-color": "--color-primary",
			"width": "50px",
			"opacity": "0.8"
		}
	},
	"FailedQuestions": {
		"kind": "Box",
		"props": {
			"background": "linear-gradient(0deg,rgba(38, 70, 83, 0.5) 0%,rgba(0,0,0,0) 35.7%),--color-sandyBrown",
			"display": "grid",
			"border-radius": "3px 30px 3px 30px",
			"place-self": "center",
			"hover-box-shadow": "none",
			"box-shadow": "0 20px 15px -6px --color-primary",
			"hover-transition": "all 1s ease 0s",
			"id": "questions-btn",
			"cursor": "pointer"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "25px 10px 0 25px",
			"display": "grid",
			"justify-items": "end",
			"width": "100%",
			"hover-color": "--lightD2",
			"hover-transition": "all 1s ease 0s",
			"color": "--light"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 700 32px/1.2 --fontFamily-googlePoppins",
			"color": "--vividOrange",
			"mix-blend-mode": "difference",
			"sm-font": "normal 600 32px/1.2 \"Poppins\", sans-serif",
			"as": "h2",
			"width": "100%",
			"min-height": "86.375px",
			"display": "flex",
			"align-items": "center",
			"children": "Failed Questions"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsXCircle,
			"size": "64px",
			"hover-border-color": "--color-primary",
			"width": "50px",
			"opacity": "0.8"
		}
	}
};

const FeatureMenu = ({
	purchasedItems,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);

	const handleMenuClick = id => {
		scrollToId(id, 2000, 0);
	};

	return <Section {...rest}>
		<Override slot="SectionContent" margin="0px auto 0px auto" grid-gap="60px" sm-grid-gap="30px" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Box {...override("box1")}>
				{purchasedItems.report && <Box onClick={() => handleMenuClick('report')} {...override("AnalyticalReport")}>
					<Box {...override("box3")}>
						<Text {...override("text1")} />
						<Icon {...override("icon")} />
					</Box>
				</Box>}
				{purchasedItems.certificate && <Box onClick={() => handleMenuClick('certificate')} {...override("GetCertificate")}>
					<Box {...override("box5")}>
						<Text {...override("text2")} />
						<Icon {...override("icon1")} />
					</Box>
				</Box>}
				{purchasedItems.review && <Box onClick={() => handleMenuClick('questions')} {...override("FailedQuestions")}>
					<Box {...override("box7")}>
						<Text {...override("text3")} />
						<Icon {...override("icon2")} />
					</Box>
				</Box>}
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(FeatureMenu, { ...Section,
	defaultProps,
	overrides
});
export default FeatureMenu;