import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import atomize from "@quarkly/atomize";
import { Box, Text } from '@quarkly/widgets';

const BellCurve = ({
	width = 600,
	height = 400,
	userScore = 120,
	override,
	overrides,
	defaultProps,
	...rest
}) => {
	const svgRef = useRef();
	const distributionRanges = [0, 55, 70, 85, 100, 115, 130, 145];
	const populationPercentages = ["0.1%", "2.1%", "13.6%", "34.1%", "34.1%", "13.6%", "2.1%", "0.1%"];
	useEffect(() => {
		const svg = d3.select(svgRef.current);
		svg.selectAll("*").remove(); // Clear SVG content before drawing
		// The container might not be fully initialized at first render; hence, width is set after mount

		const containerWidth = svg.node().getBoundingClientRect().width; // Define margins and graph dimensions

		const margin = {
			top: 20,
			right: 9,
			bottom: 60,
			left: 5
		},
		      graphWidth = containerWidth - margin.left - margin.right,
		      graphHeight = height - margin.top - margin.bottom;
		const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`); // Scales

		const x = d3.scaleLinear().domain([0, 150]).range([0, graphWidth]);
		const y = d3.scaleLinear().domain([0, 3]).range([graphHeight, 0]); // Generate bell curve data

		const points = d3.range(0, 150, 0.1).map(x => ({
			x: x,
			y: 1 / (15 * Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * Math.pow((x - 100) / 15, 2)) * 100 // Adjust y value to fit scale

		})); // Line generator

		const line = d3.line().x(d => x(d.x)).y(d => y(d.y)).curve(d3.curveBasis); // Draw curve

		g.append("path").datum(points).attr("fill", "none").attr("stroke", "steelblue").attr("stroke-width", 2).attr("d", line); // Function to fill under the curve

		const fillUnderCurve = (score, color) => {
			const area = d3.area().x(d => x(d.x)).y0(graphHeight).y1(d => y(d.y)).curve(d3.curveBasis);
			const pointsToFill = points.filter(point => point.x <= score);
			g.append("path").datum(pointsToFill).attr("fill", color).attr("d", area);
		}; // Fill under the curve based on userScore


		fillUnderCurve(userScore, "#2A9D8F "); // Draw vertical lines and labels

		distributionRanges.forEach((score, i) => {
			g.append("line").attr("x1", x(score)).attr("x2", x(score)).attr("y1", graphHeight).attr("y2", 0).attr("stroke", "#264653").style("stroke-dasharray", "3, 13");

			if (i > 0 && i < populationPercentages.length + 1) {
				g.append("text").attr("x", x(score) - (x(distributionRanges[i]) - x(distributionRanges[i - 1])) / 2).attr("y", y(1)).text(populationPercentages[i - 1]).style("font-size", "12px").style("writing-mode", "vertical-rl").attr("text-anchor", "middle");
			}
		}); // Draw the vertical line at userScore

		g.append("line").attr("x1", x(userScore)).attr("x2", x(userScore)).attr("y1", 0).attr("y2", graphHeight).attr("stroke", "#FC440F").attr("stroke-width", 4); // Axes

		g.append("g").attr("transform", `translate(0,${graphHeight})`).call(d3.axisBottom(x).tickValues(distributionRanges).tickFormat(d => `${d}`));
	}, [height, userScore, width]);
	return <Box {...rest}>
		      
		<Text {...override('ChartTitle')}>
			IQ Distribution
		</Text>
		      
		<svg ref={svgRef} width="100%" height={height} {...override('Chart')} />
		    
	</Box>;
};

export default atomize(BellCurve)({
	name: "BellCurve",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "BellCurve — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});