import React, { useState, useEffect, useReducer } from 'react';
import { Box, Text } from '@quarkly/widgets';
import { useOverrides } from '@quarkly/components';
import { reducer, initialState } from './Reducer';
import ComponentNotice from './ComponentNotice';
import AirTableList from './AirTableList';
import getData from './AirTableData'; // This hook allows you to debounce any fast changing value.

const useDebounce = (value, timeout) => {
	const [state, setState] = useState(value);
	useEffect(() => {
		const handler = setTimeout(() => setState(value), timeout);
		return () => clearTimeout(handler);
	}, [value, timeout]);
	return state;
};

const timeout = 3000;
const overrides = {
	'List': {
		kind: 'Box'
	},
	'Loading': {
		kind: 'Box',
		props: {
			children: <Text font-size="20px" font="--base">
				Loading...
			</Text>
		}
	}
};

const AirTable = ({
	base,
	table,
	view,
	fields,
	filterByFormula,
	sortFields,
	sortDirections,
	testShowLoading,
	onLoadingChange,
	...props
}) => {
	const {
		override,
		rest,
		ChildPlaceholder
	} = useOverrides(props, overrides);
	const [{
		records,
		isLoading,
		error
	}, dispatch] = useReducer(reducer, initialState);
	const baseProp = useDebounce(base, timeout);
	const tableProp = useDebounce(table, timeout);
	const viewProp = useDebounce(view, timeout);
	const fieldsProp = useDebounce(fields, timeout);
	const filterByFormulaProp = useDebounce(filterByFormula, timeout);
	const sortFieldsProp = useDebounce(sortFields, timeout);
	useEffect(() => {
		onLoadingChange(isLoading);
	}, [isLoading, onLoadingChange]);
	useEffect(() => {
		getData({
			base: baseProp,
			table: tableProp,
			options: {
				view: viewProp,
				fields: fieldsProp,
				filterByFormula: filterByFormulaProp,
				sort: {
					fields: sortFieldsProp,
					directions: sortDirections
				}
			},
			onRecords: records => dispatch({
				type: 'SET_RECORDS',
				records
			}),
			onError: error => dispatch({
				type: 'SET_ERROR',
				error
			})
		});
	}, [baseProp, tableProp, viewProp, fieldsProp, filterByFormulaProp, sortFieldsProp, sortDirections]);
	return <Box {...rest} font="--base">
		      
		{!isLoading && !error && <AirTableList {...override('List')} records={records} fieldsProp={fieldsProp} />}
		      
		{(isLoading || testShowLoading) && <Box {...override('Loading')}>
			          
			<Box margin-top="30px" display="grid" align-items="end" justify-items="center">
				            
				<Text margin="30px" font="--lead">
					Loading...
				</Text>
				          
			</Box>
			          
			<ChildPlaceholder slot="Loading" />
			        
		</Box>}
		      
		{error && <ComponentNotice message={error.message} />}
		    
	</Box>;
};

Object.assign(AirTable, {
	overrides,
	propInfo: {
		base: {
			title: 'Base ID',
			control: 'input',
			type: 'text',
			category: 'Data',
			weight: 1
		},
		table: {
			title: 'Table name',
			control: 'input',
			type: 'text',
			category: 'Data',
			weight: 1
		},
		view: {
			title: 'View name',
			control: 'input',
			type: 'text',
			category: 'Data',
			weight: 1
		},
		fields: {
			title: 'Show only specified fields',
			control: 'input',
			type: 'text',
			placeholder: 'e.g.: Name, Phone, Email',
			category: 'Others',
			weight: 1
		},
		filterByFormula: {
			title: 'Filter the specified fields using the formula',
			control: 'input',
			type: 'text',
			placeholder: 'e.g.: {Name} = \'Client Name\'',
			category: 'Others',
			weight: 1
		},
		sortFields: {
			title: 'Sort by field',
			control: 'input',
			type: 'text',
			placeholder: 'e.g.: Name',
			multiply: true,
			category: 'Others',
			weight: 1
		},
		sortDirections: {
			title: 'Sort direction',
			control: 'radio-group',
			variants: ['asc', 'desc'],
			multiply: true,
			category: 'Others',
			weight: 1
		},
		testShowLoading: {
			title: 'Show loading',
			control: 'checkbox',
			category: 'Test'
		}
	},
	defaultProps: {
		fields: '',
		filterByFormula: '',
		sortFields: '',
		sortDirections: 'asc'
	}
});
export default AirTable;