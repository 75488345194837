import React, { useState, useEffect } from 'react';
import atomize from '@quarkly/atomize';
import { Box, Text } from '@quarkly/widgets';
import IqTest from './IqTest';
import getQuestions from './GetQuestionsData';
import sendAnswersToServer from './SendAnswers'; // Adjust the import path as needed

import { RingLoader } from 'react-spinners';
import { useOverrides } from '@quarkly/components';

const IqTestWrapper = ({ ...props
}) => {
	const {
		override,
		rest
	} = useOverrides(props, {}, {});
	const [questionsData, setQuestionsData] = useState({
		examQuestions: []
	}); // State to store questions data

	const [loading, setLoading] = useState(true); // State to manage loading state
	//const [totalScore, setTotalScore] = useState(0);

	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [selectedAnswers, setSelectedAnswers] = useState({});
	const [totalQuestions, setTotalQuestions] = useState(0);
	/*useEffect(() => {
     console.log("selected answers: " + JSON.stringify(selectedAnswers));
 }, [selectedAnswers]);*/

	/*useEffect(() => {
     console.log(`Current Score: ${totalScore}`);
 }, [totalScore]);*/

	useEffect(() => {
		// Fetch questions when the component mounts
		const fetchQuestions = async () => {
			try {
				const data = await getQuestions(); // Assuming getQuestions returns a promise

				setQuestionsData(data); // Update state with fetched questions data

				setLoading(false); // Set loading to false once the data is fetched
			} catch (error) {
				console.error("Failed to fetch questions:", error);
				setLoading(false); // Ensure loading is set to false even if there is an error
			}
		};

		fetchQuestions();
	}, []);
	useEffect(() => {
		setTotalQuestions(questionsData.examQuestions.length);
	}, [questionsData]);

	const handleGetResults = () => {
		/*localStorage.setItem('totalScore', totalScore);
  console.log("Save total score to local storage. Score = " + totalScore);*/
		//console.log("Initialise Payment...");
		// Add session ID and email field to selectedAnswers       
		handleAnswersSubmit();
	};

	const handleAnswersSubmit = () => {
		const enhancedSelectedAnswers = enhanceSelectedAnswers(selectedAnswers);
		sendAnswersToServer(enhancedSelectedAnswers).then(() => {//console.log("Answers have been submitted successfully.");

			/*console.log("contains: " + enhancedSelectedAnswers);*/
			// You can also navigate the user to a different page or show a success message here
		}).catch(error => {
			console.error("Failed to submit answers:", error); // Handle submission error (e.g., show an error message)
		});
	};

	const goToNextQuestion = () => {
		if (currentQuestionIndex < totalQuestions) {
			setCurrentQuestionIndex(currentQuestionIndex + 1);
		} else {
			handleGetResults(); // Handle the end of the test here
		}
	};

	const goToPreviousQuestion = () => {
		if (currentQuestionIndex > 0) {
			setCurrentQuestionIndex(currentQuestionIndex - 1);
		}
	};

	const enhanceSelectedAnswers = selectedAnswers => {
		// Generate a unique session ID
		const sessionId = `session_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
		navigator.storage.persisted().then(persisted => {
			if (!persisted) {
				// Request to make storage persistent
				navigator.storage.persist().then(granted => {
					if (granted) {
						console.log("Storage will not be cleared except by explicit user action");
					} else {
						console.log("Storage may be cleared by the browser in low storage conditions");
					}
				});
			}
		}); // Calculate the expiration date, 7 days from now

		const expiration = new Date().getTime() + 7 * 24 * 60 * 60 * 1000; // Save the session ID and expiration date to localStorage

		localStorage.setItem('sessionId', JSON.stringify({
			value: sessionId,
			expires: expiration
		})); // Add sessionId and email to the selectedAnswers object

		const enhancedAnswers = { ...selectedAnswers,
			sessionId: sessionId,
			email: "" // Blank value for email

		};
		return enhancedAnswers;
	}; // Render a loading message or spinner when data is being fetched


	if (loading) return <Box margin-top="30px" display="grid" align-items="end" justify-items="center">
		            
		<Box>
			                
			<RingLoader color="#2A9D8F" />
			            
		</Box>
		            
		<Text margin="30px" font="--lead">
			Loading...
		</Text>
		        
	</Box>;
	return <Box {...rest}>
		            
		<IqTest //setTotalScore={setTotalScore}

			totalQuestions={totalQuestions}
			questionsData={questionsData}
			currentQuestionIndex={currentQuestionIndex}
			goToNextQuestion={goToNextQuestion}
			goToPreviousQuestion={goToPreviousQuestion}
			onGetResults={handleGetResults}
			selectedAnswers={selectedAnswers}
			setSelectedAnswers={setSelectedAnswers}
			override={override} //rest={rest}

		/>
		        
	</Box>;
};

export default atomize(IqTestWrapper)({
	name: "IqTestWrapper",
	description: {
		en: "IqTestWrapper — a container for the IQ test and the progress bar."
	},
	propInfo: {
		currentQuestionIndex: {
			title: "Current Question Index",
			control: "input",
			category: "Main",
			description: {
				en: "Index of the current question being displayed"
			}
		}
	}
}, {
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true
});