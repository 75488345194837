import React, { useMemo } from 'react';
import { Box } from '@quarkly/widgets';
import { useOverrides } from '@quarkly/components';
import AirTableItem from './AirTableItem';
const overrides = {
	'Item': {
		kind: 'Box'
	}
};

const AirTableList = ({
	records,
	fieldsProp,
	...props
}) => {
	const {
		override,
		rest
	} = useOverrides(props, overrides);
	const recordsList = useMemo(() => records.map(({
		id,
		fields
	}) => {
		return <AirTableItem
			key={`AirTableItem-${id}`}
			{...override('Item', `Item ${id}`)}
			id={id}
			fields={fields}
			fieldsProp={fieldsProp}
		/>;
	}), [records, override]);
	return <Box {...rest}>
		      
		{recordsList}
		    
	</Box>;
};

Object.assign(AirTableList, {
	overrides
});
export default AirTableList;