import React, { useState } from 'react';
import atomize from "@quarkly/atomize";
import { Box, Text, Button, Strong, Link, Icon } from '@quarkly/widgets';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { RingLoader } from 'react-spinners';
import { scrollToId } from "./UxUtils";
const pdfUrl = "https://storage.googleapis.com/brainranktest-storage/certificate_v7.pdf";
const customFontUrl = "https://storage.googleapis.com/brainranktest-storage/arial_unicode.ttf";
const MAX_DOWNLOADS = 3;

const CertificateGenerator = ({
	name = "Jőhn Doe",
	score = "75",
	override,
	...rest
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const generateCertificate = async () => {
		setIsLoading(true);

		try {
			// Fetch the existing PDF and custom font
			const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
			const fontBytes = await fetch(customFontUrl).then(res => res.arrayBuffer()); // Load a PDFDocument from the existing PDF bytes

			const pdfDoc = await PDFDocument.load(existingPdfBytes); // Register fontkit

			pdfDoc.registerFontkit(fontkit); // Embed a font

			const font = await pdfDoc.embedFont(fontBytes); //const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

			const generateUniqueNumber = () => {
				const timestamp = Date.now().toString();
				return timestamp.substring(timestamp.length - 12);
			}; // Get the first page of the document


			const pages = pdfDoc.getPages();
			const firstPage = pages[0];
			const pageWidth = firstPage.getWidth();
			const fontSize = 48; // Function to calculate text width

			const getTextWidth = (text, font, fontSize) => {
				const textWidth = font.widthOfTextAtSize(text, fontSize);
				return textWidth;
			}; //name = convertSpecialCharacters(name);
			// Calculate the center position for name


			const nameWidth = getTextWidth(name.toUpperCase(), font, fontSize);
			const nameX = pageWidth / 2 - nameWidth / 2; // Center align

			firstPage.drawText(name.toUpperCase(), {
				x: nameX,
				y: 340,
				size: fontSize,
				font: font,
				color: rgb(0.922, 0.690, 0.243)
			}); // Calculate the center position for score

			const scoreWidth = getTextWidth(score, font, fontSize);
			const scoreX = pageWidth / 2 - scoreWidth / 2; // Center align

			firstPage.drawText(score, {
				x: scoreX,
				y: 238,
				size: fontSize,
				font: font,
				color: rgb(0.922, 0.690, 0.243)
			});
			const uniqueNumber = generateUniqueNumber();
			firstPage.drawText(`Certificate Serial Number #${uniqueNumber}`, {
				x: 525,
				// Replace with your calculated X coordinate
				y: 50,
				// Replace with your Y coordinate
				size: 12,
				font: font,
				color: rgb(0, 0, 0)
			});

			function convertSpecialCharacters(str) {
				const conversions = {
					'á': 'a',
					'é': 'e',
					'í': 'i',
					'ó': 'o',
					'ö': 'o',
					'ő': 'o',
					'ú': 'u',
					'ü': 'u',
					'ű': 'u' // Add more conversions as needed

				};
				return str.replace(/á|é|í|ó|ö|ő|ú|ü|ű/g, match => conversions[match]);
			}

			function formatDate(date) {
				const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
				const day = date.getDate();
				const monthIndex = date.getMonth();
				const year = date.getFullYear(); // Determine the suffix for the day

				let suffix = "th";

				if (day === 1 || day === 21 || day === 31) {
					suffix = "st";
				} else if (day === 2 || day === 22) {
					suffix = "nd";
				} else if (day === 3 || day === 23) {
					suffix = "rd";
				}

				return `${months[monthIndex]} ${day}${suffix}, ${year}`;
			} // Use the function to get the formatted date


			const currentDate = formatDate(new Date());
			firstPage.drawText(currentDate, {
				x: 92,
				// Replace with your calculated X coordinate
				y: 50,
				// Replace with your Y coordinate
				size: 12,
				font: font,
				color: rgb(0, 0, 0)
			}); // Serialize the PDFDocument to bytes

			const pdfBytes = await pdfDoc.save(); // Trigger download of the PDF

			const blob = new Blob([pdfBytes], {
				type: 'application/pdf'
			});
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'certificate.pdf';
			link.click();
		} catch (error) {
			console.error('Error generating certificate:', error);
		}

		setIsLoading(false);
	};

	const guideText = "You can easily download your certificate by clicking on the button below. This certificate is a recognition of your achievement and can be kept as a record of your performance. Just one click, and you can have it for your records, display, or sharing with others.";

	const downloadCertificate = () => {
		const downloads = parseInt(localStorage.getItem('certificateDownloads') || '0', 10);

		if (downloads < MAX_DOWNLOADS) {
			localStorage.setItem('certificateDownloads', downloads + 1); //console.log(downloads);

			generateCertificate(); // Proceed with download
		} else {
			alert('Download limit reached');
		}
	};

	return <Box {...rest}>
		      
		<Box>
			        
			<Box {...override('TitleWrapper')}>
				          
				<Text as="h2" {...override('Title')}>
					Get Your Certificate
				</Text>
				        
			</Box>
			        
			<Text font="--base" margin="25px 0" font="--base" {...override('GuideText')}>
				          
				{guideText}
				        
			</Text>
			      
		</Box>
		      
		<Button
			onClick={downloadCertificate}
			disabled={isLoading}
			border-radius="15px 0 15px 0"
			background="--color-vividOrange"
			{...override('CertGenBtn')}
		>
			        Download Certificate
      
		</Button>
		      
		<Text {...override('Label')}>
			Once clicked, download will start automatically in a few seconds.
		</Text>
		      
		{isLoading && <Box margin-top="30px" display="grid" align-items="end" justify-items="center">
			          
			<Box>
				            
				<RingLoader color="#2A9D8F" />
				          
			</Box>
			          
			<Text margin="30px" font="--lead">
				Generating certificate...
			</Text>
			        
		</Box>}
		    
	</Box>;
};

export default atomize(CertificateGenerator)({
	name: "CertificateGenerator",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "CertificateGenerator — my awesome component"
	},
	propInfo: {
		name: {
			control: "input"
		},
		score: {
			control: "input"
		}
	}
});