import React, { useState, useEffect } from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Icon, Button, Strong, LinkBox } from "@quarkly/widgets";
import { FaBrain, FaChartBar, FaSearch } from "react-icons/fa";
import { MdSchool } from "react-icons/md";
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px",
	"text-align": "center",
	"width": "80%",
	"md-width": "80%",
	"sm-width": "100%",
	"border-radius": "24px",
	"id": "price-box",
	"lg-width": "100%"
};
const propInfo = {};
const overrides = {
	"PPInnerWrapper": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"background": "--color-light",
			"align-items": "center",
			"flex-direction": "column",
			"border-width": "2px",
			"border-style": "solid",
			"border-color": "--color-secondary",
			"lg-padding": "48px 35px 56px 35px",
			"position": "relative",
			"justify-content": "flex-start",
			"border-radius": "24px",
			"sm-padding": "0 0 0 0",
			"padding": "0px 0px 16px 0px",
			"box-shadow": "--xxl"
		}
	},
	"SideLabel": {
		"kind": "Text",
		"props": {
			"color": "#ffffff",
			"background": "--color-secondary",
			"font": "normal 300 13px/1.5 --fontFamily-googlePoppins",
			"position": "absolute",
			"transform": "rotate(90deg)",
			"right": "-38px",
			"left": "auto",
			"top": "70px",
			"bottom": "auto",
			"border-radius": "0px 0px 12px 12px",
			"padding": "0px 10px 0px 10px",
			"margin": "16px -2px 16px 0px",
			"children": "get certified"
		}
	},
	"MainTitle": {
		"kind": "Text",
		"props": {
			"margin": "45px 10px 0 10px",
			"font": "normal 500 32px/1.2 --fontFamily-googlePoppins",
			"children": "Unlock Your Results"
		}
	},
	"TotalPriceWrapper": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"position": "relative",
			"display": "flex",
			"justify-content": "center",
			"align-items": "center",
			"width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "none",
			"position": "absolute",
			"left": "5px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "20px 0px 20px 0px",
			"text-decoration": "line-through",
			"font": "italic 500 40px/1.2 --fontFamily-googlePoppins",
			"color": "--primary",
			"children": "£6"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex"
		}
	},
	"TotalPriceInt": {
		"kind": "Text",
		"props": {
			"margin": "20px 0px 20px 0px",
			"font": "--headline1",
			"color": "--primary",
			"children": "£12."
		}
	},
	"TotalPriceDecimals": {
		"kind": "Text",
		"props": {
			"margin": "20px 0px 20px 0px",
			"font": "normal 600 30px/1.5 --fontFamily-googlePoppins",
			"color": "--primary",
			"children": "37"
		}
	},
	"Get-IQScore": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"background": "linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(241, 246, 249, 0.3) 100%),--color-sandyBrown",
			"width": "100%",
			"grid-template-columns": "repeat(2, 1fr)",
			"display": "flex",
			"min-height": "106px",
			"padding": "5px 0px 5px 0px",
			"position": "relative"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"display": "grid",
			"align-items": "center",
			"justify-items": "center"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaBrain,
			"size": "64px",
			"color": "--primary",
			"mix-blend-mode": "color-dodge"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"width": "100%",
			"margin": "0px 15px 0px 0px",
			"display": "grid",
			"align-items": "start",
			"align-content": "start"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"color": "--primary",
			"text-align": "right",
			"font": "normal 600 20px/1.5 --fontFamily-googlePoppins",
			"flex": "1 1 auto",
			"margin": "5px 0px 5px 0px",
			"children": "IQ score"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"color": "--primary",
			"text-align": "right",
			"font": "normal 400 17px/1.5 --fontFamily-googlePoppins",
			"flex": "1 1 auto",
			"margin": "5px 0px 5px 0px",
			"children": "Access your IQ report after payment."
		}
	},
	"Get-Certificate": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"background": "linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(241, 246, 249, 0.3) 100%),--color-secondary",
			"width": "100%",
			"grid-template-columns": "repeat(2, 1fr)",
			"display": "flex",
			"padding": "5px 0px 5px 0px",
			"min-height": "106px",
			"position": "relative",
			"filter": "saturate(100%)"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"display": "grid",
			"align-items": "center",
			"justify-items": "center"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"type": "button",
			"position": "absolute",
			"right": 0,
			"top": 0,
			"bottom": "65%",
			"width": "100px",
			"padding": "8px 10px 8px 10px",
			"mix-blend-mode": "hard-light",
			"box-shadow": "--xl",
			"border-width": "0 0 2px 2px",
			"border-style": "solid",
			"border-color": "--color-lightD2",
			"border-radius": "0 0 0 15px",
			"display": "grid",
			"align-items": "center",
			"justify-items": "center",
			"focus-box-shadow": "--xl",
			"active-border-color": "transparent",
			"id": "addon-certi"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"children": "Remove"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdSchool,
			"size": "64px",
			"color": "--primary",
			"mix-blend-mode": "color-dodge",
			"align-self": "end"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"width": "100%",
			"margin": "0px 15px 0px 0px",
			"display": "grid",
			"align-items": "start",
			"align-content": "start"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"color": "--light",
			"text-align": "right",
			"font": "normal 600 20px/1.5 --fontFamily-googlePoppins",
			"flex": "1 1 auto",
			"margin": "5px 0px 5px 0px",
			"children": "Personalised IQ Certificate"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"color": "--light",
			"text-align": "right",
			"font": "normal 400 17px/1.5 --fontFamily-googlePoppins",
			"flex": "1 1 auto",
			"margin": "5px 0px 5px 0px",
			"children": "Your IQ certificate available for download and print in PDF format."
		}
	},
	"Get-AnReport": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "106px",
			"background": "linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(241, 246, 249, 0.3) 100%),--color-sandyBrown",
			"width": "100%",
			"grid-template-columns": "repeat(2, 1fr)",
			"display": "flex",
			"padding": "5px 0px 5px 0px",
			"position": "relative"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"align-items": "center",
			"justify-items": "center"
		}
	},
	"button1": {
		"kind": "Button",
		"props": {
			"type": "button",
			"position": "absolute",
			"right": 0,
			"top": 0,
			"bottom": "65%",
			"width": "100px",
			"padding": "8px 10px 8px 10px",
			"mix-blend-mode": "hard-light",
			"box-shadow": "--xl",
			"border-width": "0 0 2px 2px",
			"border-style": "solid",
			"border-color": "--color-lightD2",
			"border-radius": "0 0 0 15px",
			"display": "grid",
			"align-items": "center",
			"justify-items": "center",
			"focus-box-shadow": "--xl",
			"active-border-color": "transparent",
			"id": "addon-certi"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"children": "Remove"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaChartBar,
			"size": "64px",
			"color": "--primary",
			"mix-blend-mode": "color-dodge",
			"align-self": "end"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "100%",
			"margin": "0px 15px 0px 0px",
			"display": "grid",
			"align-items": "start",
			"align-content": "start"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"color": "--primary",
			"text-align": "right",
			"font": "normal 600 20px/1.5 --fontFamily-googlePoppins",
			"flex": "1 1 auto",
			"margin": "5px 0px 5px 0px",
			"children": "Analytical report"
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"color": "--primary",
			"text-align": "right",
			"font": "normal 400 17px/1.5 --fontFamily-googlePoppins",
			"flex": "1 1 auto",
			"margin": "5px 0px 5px 0px",
			"children": "Concise analytical report provided."
		}
	},
	"Get-RevQuestions": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "106px",
			"background": "linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(241, 246, 249, 0.3) 100%),--color-secondary",
			"width": "100%",
			"grid-template-columns": "repeat(2, 1fr)",
			"display": "flex",
			"padding": "5px 0px 5px 0px",
			"position": "relative"
		}
	},
	"box14": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"align-items": "center",
			"justify-items": "center"
		}
	},
	"button2": {
		"kind": "Button",
		"props": {
			"type": "button",
			"position": "absolute",
			"right": 0,
			"top": 0,
			"bottom": "65%",
			"width": "100px",
			"padding": "8px 10px 8px 10px",
			"mix-blend-mode": "hard-light",
			"box-shadow": "--xl",
			"border-width": "0 0 2px 2px",
			"border-style": "solid",
			"border-color": "--color-lightD2",
			"border-radius": "0 0 0 15px",
			"display": "grid",
			"align-items": "center",
			"justify-items": "center",
			"focus-box-shadow": "--xl",
			"active-border-color": "transparent",
			"id": "addon-certi"
		}
	},
	"text13": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"children": "Remove"
		}
	},
	"icon3": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaSearch,
			"size": "60px",
			"color": "--primary",
			"mix-blend-mode": "color-dodge",
			"align-self": "end"
		}
	},
	"box15": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "100%",
			"margin": "0px 15px 0px 0px",
			"display": "grid",
			"align-items": "start",
			"align-content": "start"
		}
	},
	"text14": {
		"kind": "Text",
		"props": {
			"color": "--light",
			"text-align": "right",
			"font": "normal 600 20px/1.5 --fontFamily-googlePoppins",
			"flex": "1 1 auto",
			"margin": "5px 0px 5px 0px",
			"children": "Review questions"
		}
	},
	"text15": {
		"kind": "Text",
		"props": {
			"color": "--light",
			"text-align": "right",
			"font": "normal 400 17px/1.5 --fontFamily-googlePoppins",
			"flex": "1 1 auto",
			"margin": "5px 0px 5px 0px",
			"children": "Examine the questions answered incorrectly."
		}
	},
	"PPFooter": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "106px",
			"padding": "5px 0px 5px 0px",
			"position": "relative"
		}
	},
	"text16": {
		"kind": "Text",
		"props": {
			"color": "--dark",
			"text-align": "center",
			"font": "normal 400 17px/1.5 --fontFamily-googlePoppins",
			"flex": "1 1 auto",
			"children": <>
				Obtain your detailed score and download your personalised PDF certificate with a single
				<br />
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					secured purchase
				</Strong>
				.
			</>
		}
	}
};

const buttonLabel = added => {
	return added ? "Remove" : "Add";
};

const PricePanel = ({
	purchasedItems,
	priceTotal,
	priceMax = 10,
	priceBase = 3,
	priceCerti = 3.5,
	priceReport = 3.5,
	priceReview = 3.5,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);

	const calcTotalPrice = () => {
		let total = 0;
		if (features.certificate) total += parseFloat(priceCerti);
		if (features.report) total += parseFloat(priceReport);
		if (features.review) total += parseFloat(priceReview);
		total += parseFloat(priceBase);
		const result = total > parseFloat(priceMax) ? parseFloat(priceMax) : total;
		priceTotal(result);
		purchasedItems(features);
		return result;
	};

	const [features, setFeatures] = useState({
		certificate: true,
		report: true,
		review: true
	});

	const toggleFeature = featureKey => {
		setFeatures(prevFeatures => ({ ...prevFeatures,
			[featureKey]: !prevFeatures[featureKey]
		}));
	};

	const featureWrapperStyle = key => {
		return {
			filter: features[key] ? 'none' : 'saturate(0%)'
		};
	};

	return <Box {...rest}>
		<Box {...override("PPInnerWrapper")}>
			<Text {...override("SideLabel")} />
			<Text {...override("MainTitle")} />
			<Box {...override("TotalPriceWrapper")}>
				<Box {...override("box2")}>
					<Text {...override("text2")} />
				</Box>
				<Box {...override("box3")}>
					<Text {...override("TotalPriceInt")}>
						£
						{Math.trunc(calcTotalPrice())}
						.
					</Text>
					<Text {...override("TotalPriceDecimals")}>
						{(calcTotalPrice() - Math.trunc(calcTotalPrice())).toFixed(2).slice(2)}
					</Text>
				</Box>
			</Box>
			<Box {...override("Get-IQScore")}>
				<Box {...override("box6")}>
					<Text {...override("text5")} />
					<Text {...override("text6")} />
				</Box>
				<Box {...override("box5")}>
					<Icon {...override("icon")} />
				</Box>
			</Box>
			<Box {...override("Get-Certificate")} style={featureWrapperStyle('certificate')}>
				<Box {...override("box9")}>
					<Text {...override("text8")} />
					<Text {...override("text9")} />
				</Box>
				<Box {...override("box8")}>
					<Button onClick={() => toggleFeature('certificate')} {...override("button")}>
						<Text {...override("text7")}>
							{buttonLabel(features.certificate)}
						</Text>
					</Button>
					<Icon {...override("icon1")} />
				</Box>
			</Box>
			<Box {...override("Get-AnReport")} style={featureWrapperStyle('report')}>
				<Box {...override("box12")}>
					<Text {...override("text11")} />
					<Text {...override("text12")} />
				</Box>
				<Box {...override("box11")}>
					<Button onClick={() => toggleFeature('report')} {...override("button1")}>
						<Text {...override("text10")}>
							{buttonLabel(features.report)}
						</Text>
					</Button>
					<Icon {...override("icon2")} />
				</Box>
			</Box>
			<Box {...override("Get-RevQuestions")} style={featureWrapperStyle('review')}>
				<Box {...override("box15")}>
					<Text {...override("text14")} />
					<Text {...override("text15")} />
				</Box>
				<Box {...override("box14")}>
					<Button onClick={() => toggleFeature('review')} {...override("button2")}>
						<Text {...override("text13")}>
							{buttonLabel(features.review)}
						</Text>
					</Button>
					<Icon {...override("icon3")} />
				</Box>
			</Box>
			<Box {...override("PPFooter")}>
				<Text {...override("text16")} />
			</Box>
		</Box>
		{children}
	</Box>;
};

Object.assign(PricePanel, { ...Box,
	defaultProps,
	overrides,
	propInfo
});
export default PricePanel;