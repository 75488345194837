import React, { useState, useEffect } from "react";
import { useOverrides } from "@quarkly/components";
import { Section } from "@quarkly/widgets";
import BellCurve from "./BellCurve";
const defaultProps = {};
const overrides = {};

const ChartPreview = props => {
	const {
		children,
		override,
		rest
	} = useOverrides(props, overrides, defaultProps); // State to hold the current score

	const [score, setScore] = useState(0);
	const [isIncreasing, setIsIncreasing] = useState(true);
	useEffect(() => {
		let animationFrameId;

		const animateScore = () => {
			setScore(prevScore => {
				let newScore = isIncreasing ? prevScore + 0.5 : prevScore - 0.5;

				if (newScore >= 150) {
					setIsIncreasing(false);
					newScore = 150;
				} else if (newScore <= 0) {
					setIsIncreasing(true);
					newScore = 0;
				}

				return newScore;
			});
			animationFrameId = requestAnimationFrame(animateScore);
		};

		animationFrameId = requestAnimationFrame(animateScore);
		return () => cancelAnimationFrame(animationFrameId);
	}, [isIncreasing]);
	return <Section {...rest}>
		      
		{children}
		      
		<BellCurve userScore={score} override={override} />
		    
	</Section>;
};

Object.assign(ChartPreview, { ...Section,
	defaultProps,
	overrides
});
export default ChartPreview;