import React, { useEffect } from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Icon, LinkBox, Section, Link } from "@quarkly/widgets";
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
	gtmId: 'GTM-5QXQ8HQ9'
};
const defaultProps = {
	"padding": "80px 0 50px 0",
	"background": "--color-darkL1",
	"quarkly-title": "Footer"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-width": "100%",
			"flex-direction": "row",
			"lg-flex-direction": "column",
			"justify-content": "space-between",
			"width": "100%",
			"padding": "0 0px 0 0px",
			"sm-flex-direction": "column",
			"sm-padding": "0 0px 0 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"lg-margin": "0px 0px 0px 0px",
			"width": "50%",
			"display": "flex",
			"lg-width": "70%",
			"sm-width": "100%",
			"flex-direction": "column",
			"padding": "0px 50px 0px 0px",
			"lg-padding": "0px 0 0px 0px",
			"sm-margin": "0px 0px 35px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0 0px 35px 0px",
			"font": "--base",
			"color": "--lightD2",
			"sm-text-align": "left",
			"sm-margin": "0 0px 0 0px",
			"lg-max-width": "640px",
			"children": <>
				The content provided here is for informational and educational purposes and should not replace professional medical guidance, diagnosis, or treatment.
				<br />
				An IQ test measures specific aspects of cognitive ability and should not be seen as the sole indicator of one’s intellectual capacity or potential.
			</>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"padding": "50px 0px 0px 0px",
			"border-color": "#232a44",
			"md-padding": "30px 0px 0px 0px",
			"md-flex-direction": "column",
			"lg-padding": "40px 0px 0px 0px",
			"align-items": "flex-end",
			"height": "auto",
			"md-align-items": "flex-start",
			"align-self": "flex-end"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--lightBlue",
			"md-margin": "0px 0px 25px 0px",
			"children": "© 2024 IQResearches by WaveDropper LTD. All rights reserved."
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"font": "--base",
			"color": "--lightD2",
			"text-decoration": "none",
			"visited-color": "--lightD2",
			"href": "/tandc",
			"hover-color": "--lightBlue",
			"children": "Terms & Conditions"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"font": "--base",
			"color": "--lightD2",
			"text-decoration": "none",
			"href": "/cookie-policy",
			"hover-color": "--lightBlue",
			"children": "Cookie Policy"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"font": "--base",
			"color": "--lightD2",
			"text-decoration": "none",
			"href": "https://ec.europa.eu/consumers/odr",
			"hover-color": "--lightBlue",
			"children": "EU ODR"
		}
	},
	"box-right": {
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	}
};
export const sendEvent = args => {
	TagManager.dataLayer(args);
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	useEffect(() => {
		TagManager.initialize(tagManagerArgs);
	}, []);
	return <Section {...rest}>
		<Override slot="SectionContent" justify-content="flex-start" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
			</Box>
			<Box {...override("box-right")}>
				<Link {...override('link1')} />
				<Link {...override('link2')} />
				<Link {...override('link3')} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Text {...override("text1")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;