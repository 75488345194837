import React, { useState, useEffect, useRef } from 'react';
import atomize from "@quarkly/atomize";
import { Box, Text, Input } from "@quarkly/widgets";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { RingLoader } from 'react-spinners';
import TagManager from "react-gtm-module";

const Paypal = ({
	name,
	email,
	clientId,
	price,
	itemName,
	currencyCode,
	itemDescription,
	onSuccess,
	isTrueSession,
	...props
}) => {
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		setIsLoading(false);
	}, []);

	const sendInitPurhcaseEvent = () => {
		const _sessionId = localStorage.getItem('sessionId');

		const tagManagerArgs = {
			dataLayer: {
				event: 'initialise purchase',
				// Use a specific event name
				transactionId: _sessionId
			}
		};
		TagManager.dataLayer(tagManagerArgs); //console.log("init purchase event ", tagManagerArgs);
	};

	return <PayPalScriptProvider options={{
		"client-id": clientId,
		currency: currencyCode
	}}>
		      
		<Box {...props}>
			        
			{isLoading ? <Box display="grid" justify-items="center">
				            
				<RingLoader color="#2A9D8F" />
				            
				<Text margin="30px" font="--lead">
					Loading...
				</Text>
				          
			</Box> : <PayPalButtons style={{
				layout: 'vertical',
				label: "checkout"
			}} // Adjust styling as necessary
			disabled={!isTrueSession} createOrder={(data, actions) => {
				return actions.order.create({
					purchase_units: [{
						amount: {
							currency_code: currencyCode,
							value: price,
							breakdown: {
								item_total: {
									currency_code: currencyCode,
									value: price
								}
							}
						},
						items: [{
							name: itemName,
							description: itemDescription,
							unit_amount: {
								currency_code: currencyCode,
								value: price
							},
							quantity: "1"
						}]
					}],
					application_context: {
						shipping_preference: "NO_SHIPPING"
					}
				});
			}} onApprove={(data, actions) => {
				return actions.order.capture().then(details => {
					// onSuccess callback implementation
					onSuccess(details, data);
				});
			}} />}
			      
		</Box>
		    
	</PayPalScriptProvider>;
};

export default atomize(Paypal)({
	// Example configuration
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: "QuarklyPaypalButton — A custom PayPal button component",
	propInfo: {
		clientId: {
			title: "Client ID",
			control: "input",
			category: "Main",
			weight: 1
		} // Add other prop configurations here

	}
}, {// Styles or other options
});