import React, { useState, useEffect } from 'react';
import atomize from "@quarkly/atomize";
import getData from "./AirTableData";
import { Section, Box, Input, Text, Image, Button } from '@quarkly/widgets';
import { RingLoader } from 'react-spinners';

function formatDate(dateString) {
	const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	const date = new Date(dateString);
	const day = date.getDate();
	const monthIndex = date.getMonth();
	const year = date.getFullYear(); // Function to add ordinal suffix to day

	function getOrdinalSuffix(day) {
		if (day > 3 && day < 21) return 'th'; // for days like 4th, 5th to 20th

		switch (day % 10) {
			case 1:
				return "st";

			case 2:
				return "nd";

			case 3:
				return "rd";

			default:
				return "th";
		}
	}

	return `${months[monthIndex]} ${day}${getOrdinalSuffix(day)} ${year}`;
}

const RetrieveResults = ({ ...props
}) => {
	const [email, setEmail] = useState('');
	const [statusMessage, setStatusMessage] = useState('');
	const [showOtp, setShowOtp] = useState(false);
	const [otp, setOtp] = useState('');
	const [sessions, setSessions] = useState([]);
	const [carouselIndex, setCarouselIndex] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {//console.log(carouselIndex);
	}, [carouselIndex]);

	const updateSessionIndexes = sessionsData => {
		const initialIndexes = {};
		sessionsData.forEach(session => {
			initialIndexes[session.id] = 0; // Initialize each session's carousel index to 0
		});
		setCarouselIndex(initialIndexes);
	}; // Function to fetch all questions for wrong answers of a session


	const fetchQuestionsForSession = async wrongAnswers => {
		const questionIds = wrongAnswers.map(answerId => answerId.toString().substring(0, 5));
		const uniqueQuestionIds = [...new Set(questionIds)]; // Adjusted to manage carousel index for each session

		const questions = await Promise.all(uniqueQuestionIds.map(questionId => new Promise((resolve, reject) => {
			getData({
				base: "appzDuvSXNQvAOgrx",
				table: "TestQA",
				options: {
					view: "DefaultGrid",
					filterByFormula: `{QuestionId} = "${questionId}"`,
					fields: ""
				},
				onRecords: resolve,
				onError: reject
			});
		})));
		return questions.flat(); // Assuming getData resolves with the record data directly
	};

	const handleEmailSubmit = async event => {
		event.preventDefault(); // Prevent the form from submitting in the traditional way

		const submittedEmail = event.target.elements.emailInput.value; // Access the input's value from the form

		setEmail(submittedEmail);
		setStatusMessage('Checking email address...');
		const payload = {
			// Adjust the URL path according to your setup
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email: submittedEmail
			})
		}; //console.log("payload of handleEmailSubmit " + JSON.stringify(payload));

		try {
			const response = await fetch('https://iqresearches.com/.netlify/functions/sendOTP', payload); //const response = await fetch('/.netlify/functions/sendOTP', payload);

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const data = await response.json();
			setStatusMessage(data.message); // Assuming the server responds with a message

			setShowOtp(true);
		} catch (error) {
			setStatusMessage('No results found for this email address.', error.message);
		}
	};

	const handleOtpSubmit = async event => {
		event.preventDefault();
		setIsLoading(true); // Start loading

		setStatusMessage('Checking verification code...');
		const payloadOtp = {
			// Adjust the URL path according to your setup
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"email": email,
				"otp": otp
			})
		}; //console.log("payload of handleOtpSubmit= " + JSON.stringify(payloadOtp));

		try {
			const response = await fetch('https://iqresearches.com/.netlify/functions/verifyOTP', payloadOtp); //const response = await fetch('/.netlify/functions/verifyOTP', payloadOtp);

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const _sessionsData = await response.json();

			const sessionsData = _sessionsData.savedSessions;
			setShowOtp(false); //console.log("Sessions Data= ", sessionsData); // Log the parsed data

			setStatusMessage(_sessionsData.message || "Verified successfully."); // Assuming sessionsData correctly contains an array of sessions...

			for (const session of sessionsData) {
				const wrongAnswers = JSON.parse(session.fields.WrongAnswers || '[]');
				const reviewPaid = JSON.parse(session.fields.Review ? true : false);
				session.questions = reviewPaid ? await fetchQuestionsForSession(wrongAnswers) : [];
			}

			setSessions(sessionsData);
			updateSessionIndexes(sessionsData);
			setIsLoading(false);
			setStatusMessage(""); // You might want to update this message based on successful operations or keep it for user feedback
		} catch (error) {
			setStatusMessage("Incorrect verification code.");
			setIsLoading(false); // Ensure loading ends even if there's an error
		}
	}; // Function to handle next image in carousel for a specific session


	const nextQuestion = sessionId => {
		setCarouselIndex(prevIndex => {
			const sessionQuestionsLength = sessions.find(session => session.id === sessionId).questions.length;
			const nextIndex = (prevIndex[sessionId] + 1) % sessionQuestionsLength;
			return { ...prevIndex,
				[sessionId]: nextIndex
			};
		});
	}; // Function to handle previous image in carousel for a specific session


	const prevQuestion = sessionId => {
		setCarouselIndex(prevIndex => {
			const sessionQuestionsLength = sessions.find(session => session.id === sessionId).questions.length;
			const prevIndexValue = prevIndex[sessionId];
			const newIndex = prevIndexValue - 1 < 0 ? sessionQuestionsLength - 1 : prevIndexValue - 1;
			return { ...prevIndex,
				[sessionId]: newIndex
			};
		});
	};

	return <Section slot="SectionContent" {...props}>
		      
		<Box align-self="center">
			        
			<form onSubmit={handleEmailSubmit}>
				          
				<Input
					border-radius="15px 0 0 15px"
					sm-border-radius="15px 15px 0 0"
					type="email"
					name="emailInput"
					placeholder="Enter your email address"
					sm-width="100%"
					required
				/>
				          
				<Button
					border-radius="0 15px 15px 0"
					sm-border-radius="0px 0 15px 15px"
					sm-width="100%"
					type="submit"
					disabled={showOtp}
				>
					            Submit
          
				</Button>
				        
			</form>
			      
		</Box>
		      
		<Box align-self="center" margin-top="30px">
			        
			{showOtp && <Box>
				            
				<Text margin-bottom="40px" text-align="center" font="--font-base" max-width="600px">
					              Check your email for the verification code and enter it below.
              Please note: The code expires after 10 minutes.
            
				</Text>
				            
				<Box max-width="200px" margin="0 auto" text-align="center">
					              
					<form onSubmit={handleOtpSubmit}>
						                
						<Input
							border-radius="15px 15px 0 0"
							text-align="right"
							width="150px"
							type="number"
							value={otp}
							onChange={e => setOtp(e.target.value)}
							placeholder="000000"
							required
							letter-spacing="3px"
						/>
						                
						<Box>
							<Button width="150px" type="submit" border-radius="0px 0 15px 15px">
								Verify
							</Button>
						</Box>
						              
					</form>
					            
				</Box>
				          
			</Box>}
			      
		</Box>
		      
		{
			/* Render Loading message when isLoading is true */
		}
		      
		{isLoading && <Box margin-top="30px" display="grid" align-items="end" justify-items="center">
			          
			<Box>
				            
				<RingLoader color="#2A9D8F" />
				          
			</Box>
			          
			<Text margin="30px" font="--lead">
				Loading...
			</Text>
			        
		</Box>}
		      
		{sessions.length < 1 && !isLoading && <Box margin-top="30px" display="grid" align-items="end" justify-items="center">
			          
			<Text margin="30px" font="--lead">
				{statusMessage}
			</Text>
			        
		</Box>}
		      
		{sessions.length > 0 && sessions.map((session, index) => <Box
			key={session.id}
			display="grid"
			grid-template-columns="repeat(2, 1fr)"
			margin="80px 0px 0px 0px"
			background="--color-light"
			sm-grid-template-columns="repeat(1, 1fr)"
		>
			          
			{
				/* Session details */
			}
			          
			<Box padding="0 0px 0px 25px" display="grid" align-content="start" grid-gap="16px">
				            
				<Box>
					              
					<Text font="--headline2" color="--primary">
						Session 
						{index + 1}
					</Text>
					              
					<Text font="--lead" color="--primary">
						                Total Score:&nbsp;
						{session.fields.TotalScore}
						              
					</Text>
					              
					<Text font="--lead" color="--primary">
						Test taken on: 
						{formatDate(session.fields.Created)}
					</Text>
					            
				</Box>
				            
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="flex-start"
					align-items="center"
					sm-justify-content="center"
				>
					              
					<Button border-radius="15px 0 0 15px" min-width="120px" onClick={() => prevQuestion(session.id)} disabled={session.questions.length <= 1}>
						                Previous
              
					</Button>
					              
					<Button border-radius="0 15px 15px 0" min-width="120px" onClick={() => nextQuestion(session.id)} disabled={session.questions.length <= 1}>
						                Next
              
					</Button>
					            
				</Box>
				          
			</Box>
			          
			{session.questions && session.questions.length > 0 ? <Box background="--color-lightD1" padding="25px 25px 25px 25px">
				              
				<Text font="--headline3" color="--primary">
					Incorrectly Answered Questions: 
					{session.questions.length}
				</Text>
				              
				<Box min-width="100px" min-height="100px">
					                
					<Text font="--lead" color="--primary">
						Question 
						{carouselIndex[session.id] + 1}
						 of 
						{session.questions.length}
					</Text>
					              
				</Box>
				              
				<Box min-width="100px" min-height="100px">
					                
					<Image src={session.questions[carouselIndex[session.id] || 0].fields.QuestionImage[0].url} alt={`Question ${carouselIndex[session.id] || 0 + 1}`} style={{
						maxWidth: '100%',
						display: 'block'
					}} />
					              
				</Box>
				            
			</Box> : <Text margin="25px" font="--base" color="--primary">
				No incorrectly answered questions were detected. Questions that were skipped are not included in this assessment.
			</Text>}
			        
		</Box>)}
		    
	</Section>;
};

export default atomize(RetrieveResults)({
	name: "RetrieveResults",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "RetrieveResults — a component to fetch and display results based on user's email, including incorrectly answered questions."
	},
	propInfo: {}
});